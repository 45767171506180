<div class="container">
  <app-close-button class="modal" (click)="close()"></app-close-button>

  <div class="body">
    <h2 class="title">
      @if (data?.title) {
        {{ data.title }}
      } @else {
        <ng-container i18n>
          Вы уверены, что хотите покинуть страницу?
        </ng-container>
      }
    </h2>

    <p class="message">
      @if (data?.message) {
        {{ data.message }}
      } @else {
        <ng-container i18n>
          Ваш отзыв в таком случае не сохранится
        </ng-container>
      }
    </p>
  </div>

  <div class="actions">
    <button appButton (click)="close(true)">
      @if (data?.confirmText) {
        {{ data.confirmText }}
      } @else {
        <ng-container i18n> Выйти без сохранения </ng-container>
      }
    </button>

    <button appButton class="outlined" (click)="close()">
      @if (data?.cancelText) {
        {{ data.cancelText }}
      } @else {
        <ng-container i18n> Вернуться к отзыву </ng-container>
      }
    </button>
  </div>
</div>
