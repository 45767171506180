import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { BtnDirective } from '../btn/btn.directive';
import { CloseButtonComponent } from '../close-button/close-button.component';

interface DialogData {
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
}

@Component({
  selector: 'app-are-you-sure',
  templateUrl: './are-you-sure.component.html',
  styleUrls: ['./are-you-sure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BtnDirective, CloseButtonComponent],
})
export class AreYouSureComponent {
  constructor(
    @Inject(DIALOG_DATA) public data: DialogData,
    private dialogRef: DialogRef
  ) {}

  public close(close: boolean = false): void {
    this.dialogRef.close(close);
  }
}
